import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import ResponsiveImage from "../common/responsiveImage"
import ResponsiveImageText from "../common/responsiveImageText"
import Clock from "../images/clock"
import config from "../../../data/SiteConfig"
import DaylightSavingCalculator from "./daylightSavingCalculator"
import ClockSquare from "../images/clockSquare"
import Switcher from "./switcher"

export default function DaylightSavingContent() {
  const data = useStaticQuery(graphql`
    query {
      introWinterTime: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(daylightSaving/1_intro_wintertime.md)/" } }
      ) {
        edges {
          node {
            html
          }
        }
      }
      introSummerTime: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(daylightSaving/1_intro_summertime.md)/" } }
      ) {
        edges {
          node {
            html
          }
        }
      }
      calculator: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(daylightSaving/2_calculator.md)/" } }
      ) {
        edges {
          node {
            html
          }
        }
      }
      attention: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(daylightSaving/3_attention.md)/" } }
      ) {
        edges {
          node {
            html
          }
        }
      }
    }
  `)

  const [useWinterTimeCalculator, setUseWinterTimeCalculator] = useState(false);

  return (
    <div>
      <ResponsiveImageText
        image={
          <ResponsiveImage
            portrait={<Clock className={`stretchWide`} />}
            landscape={<ClockSquare />}
            breakpoint={config.layoutBreakpointDesktop}
          />
        }
      >
        <Switcher 
          option1={`Sommerzeit`} 
          option2={`Winterzeit`}
          checked={useWinterTimeCalculator}
          handleChange={() => setUseWinterTimeCalculator(!useWinterTimeCalculator)}
        />
        <div
          dangerouslySetInnerHTML={
            (useWinterTimeCalculator) 
              ? { __html: data.introWinterTime.edges[0].node.html }
              : { __html: data.introSummerTime.edges[0].node.html }
          }
        />
      </ResponsiveImageText>
      <div
        dangerouslySetInnerHTML={{
          __html: data.calculator.edges[0].node.html,
        }}
      />
      <DaylightSavingCalculator useWinterTimeCalculator={useWinterTimeCalculator} />
      <div
        dangerouslySetInnerHTML={{
          __html: data.attention.edges[0].node.html,
        }}
      />
    </div>
  )
}