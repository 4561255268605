import React from "react"
import styles from "./switcher.module.css"

export default function Switcher(props) {
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.op1} ${props.checked ? `` : styles.selected}`}>{props.option1}</div>
      <input 
        type="checkbox"
        id="switch" 
        checked={props.checked}
        onChange={props.handleChange}
      />
      <label for="switch">Toggle</label>
      <div className={`${styles.op2} ${props.checked ? styles.selected : ``}`}>{props.option2}</div>
    </div>
  )
}