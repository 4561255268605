import React from "react"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import ContactUs from "../components/common/contactUs"
import DaylightSavingContent from "../components/daylightsaving/daylightSavingContent"


const title = "Zeitumstellung"

const DaylightSavingCalculatorPage = () => (
  <Layout title={title} >
    <SEO title={title} pathname="/zeitumstellung" />
    <DaylightSavingContent />
    <ContactUs />
  </Layout>
)

export default DaylightSavingCalculatorPage