import React from "react"
import styles from "./timeInput.module.css"

export default class TimeInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.initialValue,
    }

    this.name = props.name;
    this.initialValue = props.initialValue;
    this.label = props.children;
    this.handleTimeChange = props.handleTimeChange;
    this.optional = props.optional;

    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  componentDidMount() {
    this.setState({
      label: this.initialValue,
    });
  }

  updateValue(newValue) {
    this.handleTimeChange(newValue);
    this.setState({
      value: newValue
    });
  }

  handleChange(event) {
    this.updateValue(event.target.value);
  }

  handleClear() {
    this.updateValue(`--:--`);
  }

  render() {
    return (
      <label className={styles.wrapper}>
        <div className={styles.inputlabel}>{`${this.label}:`}</div>
        <input type={`time`}
               className={styles.input}
               name={this.name} 
               value={this.state.value}
               onChange={this.handleChange}
               pattern={`[0-9]{2}:[0-9]{2}`}
        />
        {
          (this.optional)
          ? <span className={styles.clearer} onClick={this.handleClear}>❌</span>
          :  ``
        }
        
        
      </label>
    )
  }
}