import React from "react"
import moment from "moment"
import styles from "./timeOutput.module.css"

export default function TimeOutput(props) {
  const value = moment(props.base, 'HH:mm')
      .subtract('minutes', props.offset)
      .format('HH:mm')

  return (
    <input 
      type={`time`}
      className={styles.output}
      value={value}
      readOnly={true}
    />
  )
}