import React from "react"
import TimeInput from "./timeInput"
import styles from "./daylightSavingCalculator.module.css"
import TimeOutput from "./timeOutput";

export default class DaylightSavingCalculator extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      value: {
        aufwachen: `06:30`,
        tagschlaf1: `08:00`,
        tagschlaf2: `11:30`,
        tagschlaf3: `14:00`,
        tagschlaf4: `16:30`,
        nachtschlaf: `19:00`,
      },
      resultsShown: false
    }
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleClick = this.handleClick.bind(this)
  }

  handleTimeChange(newValue, name) {
    let newValueDict = this.state.value;
    newValueDict[name] = newValue;

    this.setState({
      value: newValueDict
    });
  }

  handleClick() {
    this.setState({
      resultsShown: true
    });
  }
  
  render() {
    const offsetWeakup = this.props.useWinterTimeCalculator
      ? [60, 40, 20]
      : [-60, -40, -20]

    const offsetDay = this.props.useWinterTimeCalculator
      ? [40, 20, 0]
      : [-40, -20, 0]

    return (
      <div>
        <div className={styles.inputform}>
        <TimeInput 
            name={`aufwachen`}
            initialValue={this.state.value.aufwachen}
            handleTimeChange={(newValue) => this.handleTimeChange(newValue, `aufwachen`)} 
            optional={false}
          >
            Aufwachen
          </TimeInput>
          <TimeInput 
            name={`tagschlaf1`}
            initialValue={this.state.value.tagschlaf1}
            handleTimeChange={(newValue) => this.handleTimeChange(newValue, `tagschlaf1`)} 
            optional={true}
          >
            Tagschlaf 1
          </TimeInput>
          <TimeInput 
            name={`tagschlaf2`}
            initialValue={this.state.value.tagschlaf2}
            handleTimeChange={(newValue) => this.handleTimeChange(newValue, `tagschlaf2`)} 
            optional={true}
          >
            Tagschlaf 2
          </TimeInput>
          <TimeInput 
            name={`tagschlaf3`}
            initialValue={this.state.value.tagschlaf3}
            handleTimeChange={(newValue) => this.handleTimeChange(newValue, `tagschlaf3`)} 
            optional={true}
          >
            Tagschlaf 3
          </TimeInput>
          <TimeInput 
            name={`tagschlaf4`}
            initialValue={this.state.value.tagschlaf4}
            handleTimeChange={(newValue) => this.handleTimeChange(newValue, `tagschlaf4`)} 
            optional={true}
          >
            Tagschlaf 4
          </TimeInput>
          <TimeInput 
            name={`nachtschlaf`}
            initialValue={this.state.value.nachtschlaf}
            handleTimeChange={(newValue) => this.handleTimeChange(newValue, `nachtschlaf`)} 
            optional={false}
          >
            Nachtschlaf
          </TimeInput>
        </div>

        <button
          className={`action ${styles.button}`}
          onClick={this.handleClick}
        >
          Umrechnen
        </button>

        <div className={(this.state.resultsShown) ? `` : styles.hidden }>
          <div className={styles.resultHeader}>So startest du ab dem Tag der Zeitumstellung</div>
          <table className={styles.outputTable}>
            <thead>
              <tr>
                <th>Tag 1</th>
                <th>Tag 2</th>
                <th>Tag 3</th>
              </tr>
            </thead>
            <tbody>
              <tr className={styles.specialrow}>
                <td>
                  <TimeOutput 
                    base={this.state.value.aufwachen}
                    offset={offsetWeakup[0]}
                  />
                </td>
                <td>
                  <TimeOutput 
                    base={this.state.value.aufwachen}
                    offset={offsetWeakup[1]}
                  />
                </td>
                <td>
                  <TimeOutput 
                    base={this.state.value.aufwachen}
                    offset={offsetWeakup[2]}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <TimeOutput 
                    base={this.state.value.tagschlaf1}
                    offset={offsetDay[0]}
                  />
                </td>
                <td>
                  <TimeOutput 
                    base={this.state.value.tagschlaf1}
                    offset={offsetDay[1]}
                  />
                </td>
                <td>
                  <TimeOutput 
                    base={this.state.value.tagschlaf1}
                    offset={offsetDay[2]}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <TimeOutput 
                    base={this.state.value.tagschlaf2}
                    offset={offsetDay[0]}
                  />
                </td>
                <td>
                  <TimeOutput 
                    base={this.state.value.tagschlaf2}
                    offset={offsetDay[1]}
                  />
                </td>
                <td>
                  <TimeOutput 
                    base={this.state.value.tagschlaf2}
                    offset={offsetDay[2]}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <TimeOutput 
                    base={this.state.value.tagschlaf3}
                    offset={offsetDay[0]}
                  />
                </td>
                <td>
                  <TimeOutput 
                    base={this.state.value.tagschlaf3}
                    offset={offsetDay[1]}
                  />
                </td>
                <td>
                  <TimeOutput 
                    base={this.state.value.tagschlaf3}
                    offset={offsetDay[2]}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <TimeOutput 
                    base={this.state.value.tagschlaf4}
                    offset={offsetDay[0]}
                  />
                </td>
                <td>
                  <TimeOutput 
                    base={this.state.value.tagschlaf4}
                    offset={offsetDay[1]}
                  />
                </td>
                <td>
                  <TimeOutput 
                    base={this.state.value.tagschlaf4}
                    offset={offsetDay[2]}
                  />
                </td>
              </tr>
              <tr className={styles.specialrow}>
                <td>
                  <TimeOutput 
                    base={this.state.value.nachtschlaf}
                    offset={offsetDay[0]}
                  />
                </td>
                <td>
                  <TimeOutput 
                    base={this.state.value.nachtschlaf}
                    offset={offsetDay[1]}
                  />
                </td>
                <td>
                  <TimeOutput 
                    base={this.state.value.nachtschlaf}
                    offset={offsetDay[2]}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {
            this.props.useWinterTimeCalculator
            ? `Im Idealfall ist dein Kind dann ab dem vierten Tag um ${this.state.value.aufwachen} Uhr wach und du bist wieder bei euren gewohnten Zeiten.`
            : `Am vierten Tag weckst du dein Kind um ${this.state.value.aufwachen} Uhr und du bist wieder bei euren gewohnten Zeiten.`
          }
        </div>
      </div>
    )
  }
}
